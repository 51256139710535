@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";

:root {
  --aqua-blue: #00B5E2;
  --black: #192A3E;
  --dark-blue: #0033A0;
  --dark-gray: #707683;
  --dark-gray-blue: #2F3747;
  --gray: #90A0B7;
  --ignis-red: #F7685B;
  --light-gray: #C2CFE0;
  --terra-green: #18C57E;
  --ventus-yellow: #FFB946;
}

body {
  color: #333;
}

a {
  color: var(--dark-blue);
}

a:hover {
  color: var(--aqua-blue);
}

.ui.container {
  min-width: 90%;
}